
import styles from './About.module.css';

import { Link } from 'react-router-dom';

const About = () => {

    return (
        <div className={styles.about}>
            <h2>About the <span>blog</span></h2>
            <p>
                My project in firebase firestore in front-end

            </p>

            <Link to="/posts/create" className="btn">
                Create post
            </Link>


            
        </div>
    )

}

export default About;