import styles from './Login.module.css';

const Login = () => {

    return (
        <div>
            <h1>Login</h1>
        </div>
    )

}

export default Login;