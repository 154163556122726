
import styles from './Dashboard.module.css';

const Dashboard = () => {

    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    )

}

export default Dashboard;