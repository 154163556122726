
import styles from './CreatePost.module.css';

const CreatePost = () => {

    return (
        <div>
            <h1>CreatePost</h1>
        </div>
    )

}

export default CreatePost;