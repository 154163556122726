
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD9dpPc7SSEKzvQnwwLxbliGq-El5Bf_Ok",
  authDomain: "fir-89c94.firebaseapp.com",
  databaseURL: "https://fir-89c94-default-rtdb.firebaseio.com",
  projectId: "fir-89c94",
  storageBucket: "fir-89c94.appspot.com",
  messagingSenderId: "608475341482",
  appId: "1:608475341482:web:069399132c34e8e3ec26d3"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app)

export { db }