import styles from './Footer.module.css';

const Footer = () => {

    return (
        <footer className={styles.footer}>
            <h3>
                Escreva sobre o que você tem interesse!
                <p>Mini Blog &copy; 2024</p>
            </h3>
        </footer>
    )

}

export default Footer;